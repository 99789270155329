import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CousePayment = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Example API URL (Replace with your actual API endpoint)
    const apiUrl = "https://api.ocupiiehospitalityacademy.com/api/language-enquiry/fetchall"; // Update with your API endpoint

    // Making an API call using axios with .then() and .catch()
    axios.get(apiUrl)
      .then(response => {
        // On success, set the fetched data into the state
        setData(response.data);
        setLoading(false); // Stop loading once data is loaded
      })
      .catch(error => {
        // On error, set the error message into the state
        setError(error.message);
        setLoading(false); // Stop loading if there's an error
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={styles.container}>
      <h2>Enrollment Details</h2>
      <div style={styles.detailsContainer}>
        <div style={styles.detailItem}>
          <strong>Enrollment ID:</strong> {data.EnrollmentID}
        </div>
        <div style={styles.detailItem}>
          <strong>Date of Enrollment:</strong> {new Date(data.DateOfEnrollment).toLocaleDateString()}
        </div>
        <div style={styles.detailItem}>
          <strong>Amount:</strong> ${data.Amount}
        </div>
      </div>
    </div>
  );
};

// Sample styles
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  detailsContainer: {
    marginTop: '10px',
  },
  detailItem: {
    marginBottom: '8px',
    fontSize: '16px',
  },
};

export default CousePayment;
