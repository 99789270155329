import React from 'react'
import { ListGroup} from 'react-bootstrap'
import { Link } from 'react-router-dom';

const StudentSidebar = ()=> {
  return (
    <div className='centerSidebar'>
    <h2>Student Dashboard</h2>
     <ListGroup>
        <ListGroup.Item action variant="light" as={Link} to="/student/student-profile">
           Student Profile
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/student/course-details">
           Course Details
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/student/course-payment">
          Payment Status
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/student/student-syllabus">
           Syllabus
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/student/student-ebook">
          E-Book
        </ListGroup.Item>
     </ListGroup>
    </div>
  )
}

export default StudentSidebar