import React, { useState } from "react";
import { Modal} from "react-bootstrap"; // Import Modal and Button
import BusinessEnquiryForm from "../EnquiryForm/BusinessEnquiryForm"; // Ensure correct path
import "./Business.css"; // Separate CSS for Corporate Links if needed
import "bootstrap/dist/css/bootstrap.min.css";

const Business = () => {
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const handleShow = () => setShowModal(true); // Function to show the modal
  const handleClose = () => setShowModal(false); // Function to close the modal

  return (
    <div className="corporate-links">
      <h5>Corporate Links</h5>
      <a href="/franchise" className="corporate-link">
        Franchise
      </a>
      <br />
      <button variant="primary" onClick={handleShow} className="corporate-link">
        Business Enquiry
      </button><br/>
    
            <strong className="p-0 m-0">For Franchise Support:</strong>{" "}<br/>      
            <a href="tel:+91 2269718025" className="contact-link">
              +91 2269718025
            </a><br/>
          
          
            <a href="mailto:franchisee@ocupiie.com" className="contact-links">
              franchisee@ocupiie.com
            </a>
          

      {/* Modal for Business Enquiry Form */}
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton> <h2>Business Enquiry Form</h2></Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              backgroundColor: "#fffcc7",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            {/* <EnquiryFormLanguage /> */}
            <BusinessEnquiryForm />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Business;
