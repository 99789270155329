import React, { useState } from 'react';
import { Form, Button,Col, Row } from 'react-bootstrap';

const EnrollStudent = () => {
  const [formData, setFormData] = useState({
    name: '',
    fathersName: '',
    contact: '',
    email: '',
    dob: '',
    guardianContact: '',
    qualification: '',
    aadharNumber: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    city: '',
    state: '',
    district: '',
    postOffice: '',
    policeStation: '',
    photo: null,
    aadharFile: null,
    tenthCertificate: null,
    qualificationCertificate: null,
    course: '',
    centerName: '',
    paymentStatus: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: files[0],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // Send this data to your API
  };

  return (
    <div className="enroll-form">
      <h3>Enroll Student</h3>
      <Form onSubmit={handleSubmit}>
        {/* Personal Details */}
        <Row>    
        <Col xs={12} lg={6}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter full name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        <Col xs={12} lg={6}>
        <Form.Group controlId="formFathersName">
          <Form.Label>Father's Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter father's name"
            name="fathersName"
            value={formData.fathersName}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        </Row>
        <Row>
            <Col xs={12} lg={4}>
        <Form.Group controlId="formContact">
          <Form.Label>Contact</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter contact number"
            name="contact"
            value={formData.contact}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
          <Col xs={12} lg={4}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email address"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        <Col xs={12} lg={4}>
            
        <Form.Group controlId="formAadharNumber">
          <Form.Label>Aadhar Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Aadhar number"
            name="aadharNumber"
            value={formData.aadharNumber}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        </Row>
         <Row>
         <Col xs={12} lg={4}>
        <Form.Group controlId="formDob">
          <Form.Label>Date of Birth</Form.Label>
          <Form.Control
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
         <Col xs={12} lg={4}>
        <Form.Group controlId="formGuardianContact">
          <Form.Label>Guardian's Contact</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter guardian's contact number"
            name="guardianContact"
            value={formData.guardianContact}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
         <Col xs={12} lg={4}>
        <Form.Group controlId="formQualification">
          <Form.Label>Qualification</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter qualification"
            name="qualification"
            value={formData.qualification}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>        
        </Row>

       

        {/* Address Details */}
        <Form.Group controlId="formAddressLine1">
          <Form.Label>Address Line 1</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter address line 1"
            name="addressLine1"
            value={formData.addressLine1}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAddressLine2">
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter address line 2"
            name="addressLine2"
            value={formData.addressLine2}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Row>
        <Col xs={12} lg={4}>
        <Form.Group controlId="formPostalCode">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter postal code"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
         <Col xs={12} lg={4}>
        <Form.Group controlId="formCity">
          <Form.Label>City</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter city"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        <Col xs={12} lg={4}>
        <Form.Group controlId="formState">
          <Form.Label>State</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter state"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        </Row>
         <Row>
         <Col xs={12} lg={4}>
        <Form.Group controlId="formDistrict">
          <Form.Label>District</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter district"
            name="district"
            value={formData.district}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        <Col xs={12} lg={4}>
        <Form.Group controlId="formPostOffice">
          <Form.Label>Post Office</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter post office"
            name="postOffice"
            value={formData.postOffice}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
         <Col xs={12} lg={4}>
        <Form.Group controlId="formPoliceStation">
          <Form.Label>Police Station</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter police station"
            name="policeStation"
            value={formData.policeStation}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        </Row>
         <Row>
         <Col xs={12} lg={6}>
        {/* File Attachments */}
        <Form.Group controlId="formPhoto">
          <Form.Label>Attach Photo</Form.Label>
          <Form.Control
            type="file"
            name="photo"
            onChange={handleFileChange}
          />
        </Form.Group>
        </Col>
         <Col xs={12} lg={6}>
        <Form.Group controlId="formAadharFile">
          <Form.Label>Attach Aadhar File</Form.Label>
          <Form.Control
            type="file"
            name="aadharFile"
            onChange={handleFileChange}
          />
        </Form.Group>
        </Col>
        </Row>
         <Row>
         <Col xs={12} lg={6}>
        <Form.Group controlId="formTenthCertificate">
          <Form.Label>Attach 10th Certificate</Form.Label>
          <Form.Control
            type="file"
            name="tenthCertificate"
            onChange={handleFileChange}
          />
        </Form.Group>
        </Col>
         <Col xs={12} lg={6}>
        <Form.Group controlId="formQualificationCertificate">
          <Form.Label>Attach Qualification Certificate</Form.Label>
          <Form.Control
            type="file"
            name="qualificationCertificate"
            onChange={handleFileChange}
          />
        </Form.Group>
        </Col>
        </Row>
      <Row>
      <Col  xs={12} lg={6}>
        {/* Course and Center Name */}
        <Form.Group controlId="formCourse">
          <Form.Label>Course</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter course name"
            name="course"
            value={formData.course}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        <Col  xs={12} lg={6}>
        <Form.Group controlId="formCenterName">
          <Form.Label>Center Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter center name"
            name="centerName"
            value={formData.centerName}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        </Col>
        </Row>

        {/* Payment Status */}
        <Form.Group controlId="formPaymentStatus">
          <Form.Label>Payment Status</Form.Label>
          <div>
          <Row>
          <Col>
            <Form.Check
              type="radio"
              label="Full Time"
              name="paymentStatus"
              value="fullTime"
              checked={formData.paymentStatus === 'fullTime'}
              onChange={handleInputChange}
            />
            </Col>
            <Col>
            <Form.Check
              type="radio"
              label="Installment"
              name="paymentStatus"
              value="installment"
              checked={formData.paymentStatus === 'installment'}
              onChange={handleInputChange}
            />
            </Col>
            </Row>
          </div>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default EnrollStudent;
