import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export
import { Row, Col, Container, Button, Table } from "react-bootstrap";

const CenterProfile = () => {
  // State for managing preferences data and pagination
  const [preferencesData, setPreferencesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10; // Records per page

  // Fetch preferences data from the backend
  useEffect(() => {
    const fetchPreferences = () => {
      const token = localStorage.getItem("token");

      axios
        .get("https://api.ocupiiehospitalityacademy.com/api/language-enquiry/fetchall?", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setPreferencesData(response.data);
          setFilteredData(response.data); // Initially display all
        })
        .catch((error) => {
          console.error("Error fetching preferences data:", error);
        });
    };

    fetchPreferences();
  }, []);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredData.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  // Download Excel functionality
  const downloadExcel = () => {
    const dataToDownload = filteredData.length > 0 ? filteredData : preferencesData;

    const filteredDataForExcel = dataToDownload.map((item) => ({
      Center_ID: item.Center_ID,
      Center_Director: item.Center_Director,
      Center_Location: item.Center_Location,
      Status: item.Status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Preferences");
    const filename = filteredData.length > 0 ? "Filtered_Preferences" : "All_Preferences";
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <Container>
      {/* Button to download Excel */}
      <Row>
        <Col md={3}>
          <Button variant="success" className="mt-3" onClick={downloadExcel}>
            <i className="fa fa-download" aria-hidden="true"></i> Download Excel
          </Button>
        </Col>
      </Row>

      {/* Table to display preferences */}
      {filteredData.length > 0 ? (
        <Container className="text-center">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Center ID</th>
                <th>Center Director</th>
                <th>Center Location</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((item, index) => (
                <tr key={item.Center_ID}>
                  <td>{index + 1 + (currentPage - 1) * recordPerPage}</td>
                  <td>{item.Center_ID}</td>
                  <td>{item.Center_Director}</td>
                  <td>{item.Center_Location}</td>
                  <td>{item.Status}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>

            {getPageRange().map((page) => (
              <Button key={page} onClick={() => paginate(page)} active={currentPage === page}>
                {page}
              </Button>
            ))}

            <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </Button>
          </div>
        </Container>
      ) : (
        <p>No data found</p>
      )}
    </Container>
  );
};

export default CenterProfile;
