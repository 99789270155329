import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import styles from "./CareerOpportunities.module.css"; // Your custom CSS for styling

const CareerOpportunities = () => {
    const benefits = [
        "High Demand: Globalization has increased the need for multilingual professionals.",
        "Attractive Salaries: Language expertise often leads to competitive pay.",
        "Cultural Exchange: Opportunity to work in diverse cultural environments.",
        "Global Opportunities: Language skills make you eligible for international roles."
      ];
  const careerSections = [
    {
      title: "Translation and Interpretation",
      content: [
        "Translator: Convert written content (documents, books, websites, manuals) from one language to another.",
        "Interpreter: Facilitate verbal communication between speakers of different languages in business meetings, conferences, or legal proceedings."
      ]
    },
    {
      title: "Tourism and Hospitality",
      content: [
        "Tour Guide: Assist international tourists by providing tours in their native language.",
        "Travel Consultant: Plan and book travel itineraries for foreign clients.",
        "Hotel Concierge/Guest Relations Officer: Communicate with international guests in their native language to enhance their experience."
      ]
    },
    {
      title: "International Business and Trade",
      content: [
        "Export/Import Manager: Communicate with foreign clients and suppliers.",
        "Business Development Executive: Build and maintain relationships with overseas clients and partners.",
        "International Sales Representative: Manage sales in foreign markets."
      ]
    },

    {
      title: "Media and Entertainment",
      content: [
        "Subtitling Specialist: Add subtitles to movies, TV shows, or videos in different languages.",
        "Voice-over Artist: Provide voice recordings in a specific language.",
        "Content Localization Expert: Adapt content to suit the cultural and linguistic preferences of a target audience."
      ]
    },
    {
      title: "Government and Public Services",
      content: [
        "Diplomatic Services: Work as a translator, interpreter, or cultural liaison for embassies and consulates.",
        "Immigration Officer: Assist non-native speakers in immigration processes.",
        "Policy Analyst: Conduct research and provide insights on foreign policies."
      ]
    },
    {
      title: "Technology",
      content: [
        "Localization Specialist: Customize software, apps, or websites for international users.",
        "AI Language Model Trainer: Work on improving language-based AI systems.",
        "Technical Writer: Translate technical documents for global audiences."
      ]
    },
    {
        title: "Marketing and Advertising",
        content: [
          "Social Media Manager: Run campaigns targeted at foreign audiences.",
          "Content Marketer: Create multilingual content for global reach.",
          "Market Research Analyst: Analyze data from international markets."
        ]
      },
      {
        title: "Education and Training",
        content: [
          "Language Teacher/Tutor: Teach foreign languages at schools, universities, or private institutions.",
          "Corporate Trainer: Provide language training to professionals working in multinational companies."
        ]
      },
    {
      title: "Customer Support and BPO",
      content: [
        "Multilingual Customer Support Executive: Handle customer queries from international clients.",
        "Helpdesk Specialist: Provide technical support in a specific language."
      ]
    },
    {
      title: "Healthcare",
      content: [
        "Medical Interpreter: Help patients and healthcare providers communicate effectively.",
        "Medical Translator: Translate medical documents, prescriptions, and research papers."
      ]
    },
    {
      title: "Recruitment and HR",
      content: [
        "International Recruiter: Source and manage candidates from different linguistic backgrounds.",
        "Cultural Integration Specialist: Help organizations onboard employees from diverse cultures."
      ]
    },
 
    {
      title: "Aviation",
      content: [
        "Flight Attendant: Communicate with passengers from different countries.",
        "Airline Customer Service Representative: Assist travelers in their native languages."
      ]
    },
    // {
    //   title: "Legal Services",
    //   content: [
    //     "Legal Translator: Translate contracts, agreements, and legal documents.",
    //     "Court Interpreter: Provide interpretation services during legal proceedings."
    //   ]
    // }
  ];

  return (
    <Container className={`mt-5 ${styles.containerCareer}`}>
      <Row>
        <Col className="text-center">
          <h2 className="mb-4 mt-3">Known Foreign Language Career <strong style={{color:"#9ACD32"}}>Opportunities</strong></h2>
        </Col>
      </Row>      
      <Row xs={1} sm={2} md={3} lg={4} className="g-4">
        {careerSections.map((section, index) => (
          <Col key={index}>
            <Card className={`shadow-sm ${styles.cardmain}`}>
              <Card.Body className={styles.shadowBody}>
                <Card.Title className={styles.shadowBodyTitle}>
                  <FaArrowRightLong /> {section.title}
                </Card.Title>
                <ul>
                  {section.content.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

<Row>
  {/* Card displaying the benefits */}
  <Col className="pt-3 d-flex justify-content-center">
    <Card className={`shadow-sm ${styles.cardmain}`} style={{ width: 'fit-content' }}>
      <Card.Body className={styles.shadowBody}>
        <Card.Title className={styles.shadowBodyTitle}>
          Benefits of Pursuing Foreign Language Jobs
        </Card.Title>
        <ul>
          {benefits.map((benefit, idx) => (
            <li key={idx} className={styles.benefitItem}>
              {benefit}
            </li>
          ))}
        </ul>
      </Card.Body>
    </Card>
  </Col>
</Row>

    </Container>
  );
};

export default CareerOpportunities;
