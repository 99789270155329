import React from 'react'
import { ListGroup} from 'react-bootstrap'
import { Link } from 'react-router-dom';
const CenterSidebar = ()=> {
  return (
    <div className='centerSidebar'>
    <h2>Center Dashboard</h2>
     <ListGroup>
        <ListGroup.Item action variant="light" as={Link} to="/center/center-profile">
           Center Profile
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/center/total-student">
           Total Student
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/center/enroll-student">
           Enroll Student
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/center/paymentss">
           Payment Status
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/center/ebook">
          E-Book
        </ListGroup.Item>
        <ListGroup.Item action variant="light" as={Link} to="/center/center-profile">
          Promotion
        </ListGroup.Item>
     </ListGroup>
    </div>
  )
}

export default CenterSidebar