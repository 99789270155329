import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StudentSyllabus = () => {
  const [syllabusUrl, setSyllabusUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the syllabus from the backend when the component mounts
  useEffect(() => {
    // Replace with your actual API URL
    const apiUrl = "https://api.ocupiiehospitalityacademy.com/api/language-enquiry/fetchall";  // API to fetch syllabus URL

    // Fetch syllabus data using axios
    axios.get(apiUrl)
      .then(response => {
        // Assuming the response contains the syllabus URL in `syllabusUrl` field
        setSyllabusUrl(response.data.syllabusUrl);  
        setLoading(false);  // Stop loading once data is fetched
      })
      .catch(error => {
        setError(error.message);  // Handle error
        setLoading(false);
      });
  }, []);

  // Handle the click to open the syllabus in a new tab
  const handleViewClick = () => {
    if (syllabusUrl) {
      window.open(syllabusUrl, '_blank');  // Open the syllabus URL in a new tab
    }
  };

  if (loading) return <div>Loading syllabus...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={styles.container}>
      <h2>Syllabus</h2>
      {syllabusUrl ? (
        <div>
          <button style={styles.viewButton} onClick={handleViewClick}>
            View Syllabus
          </button>
        </div>
      ) : (
        <div>No syllabus available</div>
      )}
    </div>
  );
};

// Sample styles
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  viewButton: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default StudentSyllabus;
