import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "./FormContext"; // Ensure this path is correct

const Declaration = () => {
  const { formData, updateFormData } = useFormContext(); // Get form data and update function from context

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData("declaration", { [name]: value }); // Update form data in the declaration section
  };

  return (
    <div>
      <h3>Section 6: Declaration</h3>
      <Form>
        {/* Declaration Text */}
        <Form.Group controlId="declarationText">
          <Form.Label>
            I hereby declare that the information provided in this form is true and accurate to the best of my knowledge. I understand that providing false information may result in the rejection of my application.
          </Form.Label>
        </Form.Group>

        {/* Signature */}
        <Form.Group controlId="signature">
          <Form.Label>Signature:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your signature"
            name="signature"
            value={formData.declaration.signature || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Date */}
        <Form.Group controlId="declarationDate">
          <Form.Label>Date:</Form.Label>
          <Form.Control
            type="date"
            name="declarationDate"
            value={formData.declaration.declarationDate || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Place */}
        <Form.Group controlId="place">
          <Form.Label>Place:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your place"
            name="place"
            value={formData.declaration.place || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default Declaration;
