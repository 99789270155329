import React from 'react';
import { useFormContext } from './FormContext'; 
import PersonalInformation from './PersonalInformation';
import BusinessInformation from './BusinessInformation';
import { Container, Button, ProgressBar } from 'react-bootstrap';
import FinancialInformation from './FinancialInformation';
import FranchiseInterest from './FranchiseInterest';
import LegalAndCompliance from './LegalAndCompliance';
import Declaration from './Declaration';
import axios from 'axios'; // Import axios

const StepManager = () => {
  const { currentStep, nextStep, prevStep, formData } = useFormContext(); 

  // Calculate progress percentage
  const progress = ((currentStep - 1) / 5) * 100; // Assuming there are 5 steps in total

  // Function to render the current step form component
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <PersonalInformation />;
      case 2:
        return <BusinessInformation />;
      case 3:  
        return <FinancialInformation />;
      case 4:
        return <FranchiseInterest />;
      case 5:
        return <LegalAndCompliance />;
      default:
        return <Declaration />;
    }
  };

  // Function to handle form submission using axios
  const handleSubmit = () => {
    console.log(formData);
    axios.post('https://your-api-endpoint.com/submit', formData) // API POST request with form data
      .then(response => {
        console.log('Form Submitted:', response.data); // Log the successful response from backend
        alert('Form submitted successfully!');
      })
      .catch(error => {
        console.error('Error submitting form:', error); // Log any error that occurs during the request
        alert('There was an error submitting the form. Please try again.');
      });
  };

  return (
    <Container>
      {/* Progress Bar */}
      <ProgressBar now={progress} label={`${progress}%`} />

      {/* Render Form Step */}
      {renderStep()}

      {/* Navigation Buttons */}
      <div className="mt-3">
        {currentStep > 1 && (
          <Button variant="secondary" onClick={prevStep} className="mr-2">
            Previous
          </Button>
        )}
        {currentStep < 6 ? (
          <Button variant="primary" onClick={nextStep}>
            Next
          </Button>
        ) : (
          <Button variant="success" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </div>
    </Container>
  );
};

export default StepManager;
