import { Col, Container, Row, Image } from "react-bootstrap";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function WorldWidePlacement() {
  return (
    <Container className="my-4 mt-5">
      <Row className="mt-4">
        {/* left side: Text */}
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "white" }}
        >
          {/* Inline style block for media queries */}
          <style>
            {`
              .responsive-heading h1 {
                font-family: 'math-italic';
              }
              .worldcss{
              font-size: 60px;
              }

            

            .contact-linkssd {
              padding: 0; /* Remove padding */
              margin: 0; /* Ensure no margin */
              display: inline;     /* Ensure it behaves as an inline element */
              text-decoration: none; 
               }

              @media (max-width: 576px) {
                .responsive-heading h1 {
                  font-size: 18px;
                }
                  .worldcss{
              font-size: 26px;
              }
            
              .contact-linkssd{
                 font-size: 14px;
              }
       
       
            `}
          </style>
          <div className="responsive-heading text-container text-center p-3">
            <h1 style={{ fontFamily: "math-italic" }}>
              <strong className="worldcss" style={{ color: "#9acd32" }}>
                World{" "}
              </strong>
              <span style={{ color: "#ffad00" }}>Wide </span>
              <strong style={{ color: "#080809", margin: "0px", padding: "0px" }}>Placement Assistance</strong>
              <br />
            </h1>
    
            <h4 style={{ margin: 0, padding: 0 }} >
              <a
                href="mailto:placements@ocupiie.com"
                className="contact-linkssd m-0 p-0"
              >
                placements@ocupiie.com
              </a>
            </h4>
          </div>
        </Col>

        {/* right side: Image */}
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center"
        >
          <Image
            src="/images/worldwide.jpg" // Replace with the path to your image
            alt="Descriptive Alt Text"
            className="img-fluid" // Ensures the image is responsive
            style={{ maxHeight: "400px", width: "auto" }} // Adjust maxHeight as needed
          />
        </Col>
      </Row>
    </Container>
  );
}

export default WorldWidePlacement;
