import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import FooterHome from "./Home/Footer/FooterHome";
import NavBar from "./Home/NavBar/NavBar";
import Home from "./Home/Home";
import CoursePage from "./Home/CoursesPage/CoursePage";
import HospitalityCareerPage from "./Home/Hospitality/HospitalityCareerPage";
import HospitalityGuidenceCareerPage from "./Home/Hospitality/HospitalityGuidenceCareerPage";
import HospitalityIndustriesPage from "./Home/Hospitality/HospitalityIndustriesPage";
import ScrollToTop from "./Home/ScrollToTop/ScrollToTop";
import BusinessEnquiryForm from "./Home/EnquiryForm/BusinessEnquiryForm";
import LanguageOnline from "./Home/LangugeOnline/LanguageOnline";
import Franchise from "./Home/Franchise/Franchise";
import AdminDashboard from "./Admin/AdminDashboard";
import Login from "./Admin/Login";
import StudentInquiry from "./Admin/StudentInquiry";
import BusinessInquiry from "./Admin/BusinessInquiry";
import OnlineInquiry from "./Admin/OnlineInquiry";
import Offcanvas from "react-bootstrap/Offcanvas";
import PrivacyPolicy from "./Home/Footer/PrivacyPolicy";
import TermsAndConditions from "./Home/Footer/TermsAndConditions";
import RefundPolicy from "./Home/Footer/RefundPolicy";
import FeedbackForm from "./Admin/FeedbakForm";

import VideoList from "./Admin/VideoUpload/VideoList";
import PaymentStatus from "./PaymentStatus";
import PaymentRecord from "./Admin/PaymentRecord";
import CenterDashboard from "./Center/CenterDashboard";
import CenterLogin from "./Center/CenterLogin";
import StudentLogin from "./Student/StudentLogin";
import StudentDashboard from "./Student/StudentDashboard";
import CenterProfile from "./Center/CenterProfile";
import TotalStudent from "./Center/TotalStudent";
import EnrollStudent from "./Center/EnrollStudent";
import Ebook from "./Center/Ebook/Ebook";
import Payment from "./Center/Payment";
import StudentProfile from "./Student/StudentProfiles";
import CourseDetails from "./Student/CourseDetails";
import CousePayment from "./Student/CousePayment";
import StudentSyllabus from "./Student/StudentSyllabus";
import StudentEbook from "./Student/StudentEbook";
import PaymentPaid from "./Admin/PaymentPaid";
import CheckEnrollment from "./Certificate/CheckEnrollment";
import CertificateVerificationForm from "./Certificate/CertificateVerificationForm";
import VerifyOtp from "./Student/VerifyOtp";
import StepManager from "./Admin/Francjise/StepManager";
import { FormProvider } from "./Admin/Francjise/FormContext";

function App() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [offcanvasContent, setOffcanvasContent] = useState(null);
  const [previousPages, setPreviousPages] = useState([]); // Array to store last two visited pages
  const location = useLocation(); // Current location (route)
  const navigate = useNavigate(); // Navigation helper

  useEffect(() => {
    const currentPath = location.pathname;

    // Check if you're leaving the '/admin' section
    if (!currentPath.startsWith("/admin")) {
      localStorage.removeItem("token"); // Remove token when navigating away from admin routes
    }
  }, [location.pathname]); // Re-run this effect on path change


  

  // Clear the console on component mount
  useEffect(() => {
    // console.clear(); // Removed console.clear() to avoid clearing the console unnecessarily
  }, []);

  // Update the previousPages array on route change
  useEffect(() => {
    // Keep track of the last two visited locations
    if (previousPages.length === 2) {
      setPreviousPages((prev) => [prev[1], location.pathname]); // Update the array to store the last two pages
    } else {
      setPreviousPages((prev) => [...prev, location.pathname]); // Add current page to array
    }
  }, [location, previousPages.length]); // Add 'previousPages.length' as dependency to avoid the warning

  // Manage Offcanvas for policy routes (Privacy Policy, Terms and Conditions, Refund Policy)
  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath === "/privacy-policy") {
      setOffcanvasContent({
        title: "Privacy Policy",
        component: <PrivacyPolicy />,
      });
      setShowOffcanvas(true);
    } else if (currentPath === "/terms-and-conditions") {
      setOffcanvasContent({
        title: "Terms & Conditions",
        component: <TermsAndConditions />,
      });
      setShowOffcanvas(true);
    } else if (currentPath === "/refund-policy") {
      setOffcanvasContent({
        title: "Refund Policy",
        component: <RefundPolicy />,
      });
      setShowOffcanvas(true);
    } else {
      setShowOffcanvas(false); // Close Offcanvas for other routes
    }
  }, [location]);

  // Function to handle closing the Offcanvas
  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
    if (previousPages.length > 1) {
      const previousToPreviousPage = previousPages[0];
      navigate(previousToPreviousPage);
    } else {
      navigate("/"); // Default to home if no previous pages
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <ScrollToTop />

      {/* Conditionally render NavBar */}
      <ConditionalNavBar />

      {/* Main content area */}
      <div style={{ flex: 1 }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/hospitality-industries-page"
            element={<HospitalityIndustriesPage />}
          />
          <Route
            path="/hospitality-guidence-career-page"
            element={<HospitalityGuidenceCareerPage />}
          />
          <Route
            path="/hospitality-career-page"
            element={<HospitalityCareerPage />}
          />
          <Route path="/business-enquiry" element={<BusinessEnquiryForm />} />
          <Route path="/courses" element={<CoursePage />} />
          <Route path="/courses/:courseId" element={<CoursePage />} />
          <Route path="/onlinelanguages" element={<LanguageOnline />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/payment-status" element={<PaymentStatus />} />
          {/* <Route path="/franchise-form" element={<StepManager/>}/> */}


          {/* Student Login */}
          <Route path="/otp-verify" element={<VerifyOtp/>}/>
          <Route path="/check-enrollment" element={<CheckEnrollment/>}/>
          <Route path="/varify-enrollment" element={<CertificateVerificationForm/>}/>
          <Route path="/student/logins" element={<StudentLogin />} />
          <Route path="/student" element={<StudentDashboard />}>
            <Route path="student-profile" element={<StudentProfile />} />
            <Route path="course-details" element={<CourseDetails />} />
            <Route path="course-payment" element={<CousePayment />} />
            <Route path="student-syllabus" element={<StudentSyllabus />} />
            <Route path="student-ebook" element={<StudentEbook />} />
          </Route>

          {/* Center Login */}
          <Route path="/center/logins" element={<CenterLogin />} />
          <Route path="/center" element={<CenterDashboard />}>
            <Route path="center-profile" element={<CenterProfile />} />
            <Route path="total-student" element={<TotalStudent />} />
            <Route path="enroll-student" element={<EnrollStudent />} />
            <Route path="paymentss" element={<Payment />} />
            <Route path="ebook" element={<Ebook />} />
          </Route>

          {/* Admin Routes */}
          <Route path="/admin/login" element={<Login />} />

          <Route path="/admin" element={<AdminDashboard />}>
            <Route path="franchise-form" element={<FormProvider><StepManager /></FormProvider>} />
            <Route path="student-inquiry" element={<StudentInquiry />} />
            <Route path="business-inquiry" element={<BusinessInquiry />} />
            <Route path="online-language-inquiry" element={<OnlineInquiry />} />
            <Route path="feedback-inquiry" element={<FeedbackForm />} />
            <Route path="video-list" element={<VideoList />} />
            <Route path="payment-details" element={<PaymentRecord />} />
            <Route path="paid-payment-details" element={<PaymentPaid/>}/>
          </Route>

          {/* Routes for policies */}
          <Route path="/privacy-policy" element={<div />} />
          <Route path="/terms-and-conditions" element={<div />} />
          <Route path="/refund-policy" element={<div />} />

          {/* Redirect to Home for unknown routes */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>

      {/* Offcanvas for showing policies */}
      <Offcanvas
        show={showOffcanvas}
        onHide={handleCloseOffcanvas}
        placement="bottom"
        style={{
          width: "50%",
          height: "80vh",
          zIndex: 1050,
          position: "fixed",
          bottom: "0",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {offcanvasContent && offcanvasContent.title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {offcanvasContent && offcanvasContent.component}
        </Offcanvas.Body>
      </Offcanvas>

      {/* Footer will be hidden when offcanvas is open */}
      <ConditionalFooter />
    </div>
  );
}

// Conditionally render the NavBar
const ConditionalNavBar = () => {
  const location = useLocation();
  const hideNavBar =
    location.pathname.startsWith("/admin") ||
    location.pathname === "/onlinelanguages" ||
    location.pathname === "/payment-status" ||
    location.pathname.startsWith("/center") ||
    location.pathname.startsWith("/student") ||
    location.pathname ==="/otp-verify";

  return !hideNavBar ? <NavBar /> : null;
};

// Conditionally render the Footer
const ConditionalFooter = () => {
  const location = useLocation();
  const hideFooter =
    location.pathname.startsWith("/admin") ||
    location.pathname === "/payment-status" ||
    location.pathname.startsWith("/center") ||
    location.pathname ==="/otp-verify" ||
    location.pathname.startsWith("/student");

  return !hideFooter ? <FooterHome /> : null;
};

export default App;
