import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormContext } from "./FormContext"; // Ensure this path is correct

const LegalAndCompliance = () => {
  const { formData, updateFormData } = useFormContext(); // Get form data and update function from context

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData("legalAndCompliance", { [name]: value }); // Update form data in the legalAndCompliance section
  };

  return (
    <div>
      <h3>Section 5: Legal and Compliance</h3>
      <Form>
        {/* Question 1: Conviction of a Crime */}
        <Form.Group controlId="convictedOfCrime">
          <Form.Label>1. Have you ever been convicted of a crime?</Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="Yes"
                name="convictedOfCrime"
                value="Yes"
                checked={formData.legalAndCompliance.convictedOfCrime === "Yes"}
                onChange={handleInputChange}
              />
            </Col>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="No"
                name="convictedOfCrime"
                value="No"
                checked={formData.legalAndCompliance.convictedOfCrime === "No"}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </Form.Group>

        {formData.legalAndCompliance.convictedOfCrime === "Yes" && (
          <Form.Group controlId="convictionDetails">
            <Form.Label>If yes, please provide details:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter details about the conviction"
              name="convictionDetails"
              value={formData.legalAndCompliance.convictionDetails || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        )}

        {/* Question 2: Ongoing Legal Proceedings */}
        <Form.Group controlId="ongoingLegalProceedings">
          <Form.Label>2. Are there any ongoing legal proceedings against you or your business?</Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="Yes"
                name="ongoingLegalProceedings"
                value="Yes"
                checked={formData.legalAndCompliance.ongoingLegalProceedings === "Yes"}
                onChange={handleInputChange}
              />
            </Col>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="No"
                name="ongoingLegalProceedings"
                value="No"
                checked={formData.legalAndCompliance.ongoingLegalProceedings === "No"}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </Form.Group>

        {formData.legalAndCompliance.ongoingLegalProceedings === "Yes" && (
          <Form.Group controlId="legalProceedingsDetails">
            <Form.Label>If yes, please provide details:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter details about ongoing legal proceedings"
              name="legalProceedingsDetails"
              value={formData.legalAndCompliance.legalProceedingsDetails || ""}
              onChange={handleInputChange}
            />
          </Form.Group>
        )}

        {/* Question 3: Agree to Adhere to Terms and Conditions */}
        <Form.Group controlId="adhereToTerms">
          <Form.Label>3. Do you agree to adhere to all franchise terms and conditions?</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your agreement to adhere to the terms and conditions"
            name="adhereToTerms"
            value={formData.legalAndCompliance.adhereToTerms || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default LegalAndCompliance;
