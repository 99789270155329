import React from "react";
import { Modal, Button } from "react-bootstrap";

const ViewEbook = ({ showModal, setShowModal, ebook }) => {
  const handleClose = () => setShowModal(false);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{ebook.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <img
            src={`https://api.ocupiiehospitalityacademy.com/${ebook.thumbnail}`}
            alt={ebook.title}
            style={{ width: "150px", height: "auto" }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <strong>Title:</strong> {ebook.title}
        </div>
        <div style={{ marginTop: "10px" }}>
          <strong>Description:</strong> {ebook.description || "No description available."}
        </div>
        <div style={{ marginTop: "20px" }}>
          <strong>Download Ebook:</strong>
          <a
            href={`https://api.ocupiiehospitalityacademy.com/${ebook.file_path}`}
            target="_blank"
            rel="noopener noreferrer"
            download
            className="btn btn-primary"
            style={{ marginTop: "10px" }}
          >
            Download
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewEbook;
