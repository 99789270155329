import React, { useState } from 'react';
import { Card, Container, Modal } from 'react-bootstrap';

const Gallery = () => {
  // State to track whether scrolling is paused
  const [isScrolling, setIsScrolling] = useState(true);

  // State to manage the modal visibility and content
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  // Example images for the gallery
  const images = [
    '/images/Franchiseeoffer1.jpg',
    '/images/Start-your-own-business.jpg',
    '/images/franchisee-offer2.jpg',
    '/images/long-time.jpg',
    '/images/Why-Partner-with-Ocupiie.jpg',
  ];

  // Example videos for the gallery
  // const videos = [
  //   'https://www.w3schools.com/html/movie.mp4',
  //   'https://www.w3schools.com/html/movie2.mp4',
  //   'https://www.w3schools.com/html/movie3.mp4',
  // ];

  // Function to handle mouse entering (pause scrolling)
  const handleMouseEnter = () => setIsScrolling(false);

  // Function to handle mouse leaving (resume scrolling)
  const handleMouseLeave = () => setIsScrolling(true);

  // Function to open the modal with the selected content (image or video)
  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setModalContent(null);
  };

  return (
    <Container fluid={true} className="m-0 p-0" style={{ marginTop: '17px' }}>
      <h2 className="h2class text-center" style={{ paddingLeft: '50px' }}>
        Gallery
      </h2>

      {/* Images Gallery */}
      <div
        className={`scrolling-container ${isScrolling ? 'scrolling' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="scrolling-row">
          {images.map((img, index) => (
            <div key={index} className="card-wrapper">
              <Card className="cardClass">
                <Card.Img
                  variant="top"
                  src={img}
                  alt={`Image ${index + 1}`}
                  onClick={() => handleOpenModal(img)} // Open image in modal on click
                  style={{ cursor: 'pointer' }}
                />
              </Card>
            </div>
          ))}
          {/* Clone the images for seamless scrolling */}
          {images.map((img, index) => (
            <div key={`clone-${index}`} className="card-wrapper">
              <Card className="cardClass">
                <Card.Img
                  variant="top"
                  src={img}
                  alt={`Image ${index + 1}`}
                  onClick={() => handleOpenModal(img)} // Open image in modal on click
                  style={{ cursor: 'pointer' }}
                />
              </Card>
            </div>
          ))}
        </div>
      </div>

      {/* Videos Gallery */}
      {/* <div
        className={`scrolling-container ${isScrolling ? 'scrolling' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ marginTop: '20px' }}
      >
        <div className="scrolling-row">
          {videos.map((vid, index) => (
            <div key={index} className="card-wrapper">
              <Card className="cardClass">
                <video
                  width="300"
                  controls
                  onClick={() => handleOpenModal(vid)} // Open video in modal on click
                  style={{ cursor: 'pointer' }}
                >
                  <source src={vid} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Card>
            </div>
          ))} */}

          {/* Clone the videos for seamless scrolling */}
{/* 
          {videos.map((vid, index) => (
            <div key={`clone-${index}`} className="card-wrapper">
              <Card className="cardClass">
                <video
                  width="300"
                  controls
                  onClick={() => handleOpenModal(vid)} // Open video in modal on click
                  style={{ cursor: 'pointer' }}
                >
                  <source src={vid} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Card>
            </div>
          ))}
        </div>
      </div>
 */}
      {/* Modal for displaying image or video */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton />
        
       
        <Modal.Body>
          {typeof modalContent === 'string' && modalContent.endsWith('.mp4') ? (
            <video width="100%" controls>
              <source src={modalContent} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={modalContent} alt="Modal Content" width="100%" />
          )}
        </Modal.Body>
       </Modal>
    </Container>
  );
};

export default Gallery;
