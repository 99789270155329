import React, { useState } from 'react';
import axios from 'axios';
import "./Certificate.css"
import { Container, Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';

const CheckEnrollment = () => {
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [enrollmentId, setEnrollmentId] = useState('');
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false); // To manage loading state
  const [result, setResult] = useState(null); // To store the result from the backend
  const [showImageModal, setShowImageModal] = useState(false); // For modal visibility

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return; // Prevent form submission if validation fails
    }

    setValidated(true); // Set validated state after form check
    setError(''); // Reset error message
    setResult(null); // Reset previous result
    setLoading(true); // Start loading indicator

    // Prepare the data to be sent to the backend
    const studentData = { name, dob, enrollmentId };

    // Make an API request to check enrollment
    axios
      .post('https://your-api-endpoint.com/api/check-enrollment', studentData)
      .then((response) => {
        // Handle successful response
        setLoading(false);
        setResult(response.data); // Store the result from the backend
      })
      .catch((error) => {
        // Handle error responses
        setLoading(false);
        console.error('Error object:', error); // Log the full error object for debugging

        if (error.response) {
          // If there is a response from the server
          const { data } = error.response;
          setError(data?.error || 'An error occurred while checking enrollment.');
        } else {
          // If the error is not from the response (network issues, etc.)
          setError('An error occurred while communicating with the server.');
        }
      });
  };

  const handleImageClick = () => {
    setShowImageModal(true); // Show the image modal when button is clicked
  };

  const handleCloseModal = () => {
    setShowImageModal(false); // Close the modal when clicked
  };

  return (
    <Container fluid={true} className="pt-3 watercolor-container">
      <Row className="justify-content-center" style={{ minHeight: '80vh' }}>
        {/* Column for Image Button */}
        <Col
          xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} // Position the button in a left column
          className="d-flex align-items-center justify-content-center"
        >
          {/* Button to open the image in a modal */}
          <div className='headdivsample'>
          <h2 style={{color: "#9acd32"}}>Sample Certificate</h2>
          <Button
            variant="primary"
            className="w-100 py-2"
            onClick={handleImageClick}
          >
            click me
          </Button>
          </div>
        </Col>

        {/* Column for Form */}
        <Col
          xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} // Form will take the rest of the space
          className="d-flex align-items-center justify-content-center"
        >
          <div className=" divformdata p-4 rounded-3 shadow-sm mt-2 mb-2" style={{ minHeight: 'auto',backgroundColor: 'rgba(210, 209, 209, 0.7)' }}>
            <h2 className="text-center mb-3">Check Enrollment</h2>
            {error && <Alert variant="danger">{error}</Alert>}

            {/* Display the result if available */}
            {result && (
              <div className="mb-3 p-2 bg-light rounded-3 border">
                <h3>Enrollment Status</h3>
                <p>{result.status}</p>
              </div>
            )}

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                {validated && !name && <div className="text-danger">Name is required</div>}
              </Form.Group>

              <Form.Group controlId="formDob" className="mb-3">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
                {validated && !dob && <div className="text-danger">Date of Birth is required</div>}
              </Form.Group>

              <Form.Group controlId="formEnrollmentId" className="mb-3">
                <Form.Label>Enrollment ID</Form.Label>
                <Form.Control
                  type="text"
                  value={enrollmentId}
                  onChange={(e) => setEnrollmentId(e.target.value)}
                  required
                />
                {validated && !enrollmentId && <div className="text-danger">Enrollment ID is required</div>}
              </Form.Group>

              <Button type="submit" className="w-100 py-2" disabled={loading}>
                {loading ? 'Checking Enrollment...' : 'Submit'}
              </Button>
            </Form>
          </div>
        </Col>
      </Row>

      {/* Modal for Image */}
      <Modal show={showImageModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Sample Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img
            src="/images/Samplecertificate.jpg"
            alt="Sample Certificate"
            className="w-100 h-auto rounded-3"
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default CheckEnrollment;
