
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap"; // You can use any modal library or your custom modal
import EnquiryForm from "../EnquiryForm/EnquiryForm"; // Assuming your enquiry form is in this file

const EnquiryComponent = () => {
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  // Function to handle the opening and closing of the modal
  const handleEnquiryClick = () => {
    setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  return (
    <div>
      {/* Button on the right side */}
      <Button
        className="enquirenow"
        onClick={handleEnquiryClick}
        style={{
          position: "fixed",
          top: "70%",
          right: "50px",
          zIndex: "10",
          color: "white",
          backgroundColor: "#9acd32", // Match your logo color
          border: "1px",
          fontWeight: "bold",
          transform: "rotate(90deg)", // Rotate the button
          transformOrigin: "bottom right", // Set the origin of the rotation to the bottom right corner
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        Student Enquiry
      </Button>

      {/* Modal Component */}
      <Modal show={showModal} onHide={handleCloseModal}   backdrop="static"
        keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Student Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body
            style={{
              width: "100%",
              backgroundColor: "#9ACD32",
              padding: "20px",
              borderRadius: "10px",
            }}
          
        >
          {/* Enquiry Form Component */}
          <EnquiryForm style={{ height: "100%", width: "100%" }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EnquiryComponent;
