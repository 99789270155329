// import React, { useState, useEffect } from 'react';
// import axios from 'axios'; // Import Axios

// const Payment = () => {
//   // State variables
//   const [data, setData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [year, setYear] = useState('');
//   const [month, setMonth] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   // Predefined years and months (can be dynamic)
//   const years = [2021, 2022, 2023, 2024];
//   const months = [
//     'January', 'February', 'March', 'April', 'May', 'June',
//     'July', 'August', 'September', 'October', 'November', 'December'
//   ];

//   // Fetch data when the component mounts using .then() and .catch()
//   useEffect(() => {
//     setLoading(true);
//     setError(null);

//     axios
//       .get('/api/payment-status') // Make GET request with Axios
//       .then((response) => {
//         setData(response.data);  // Store the full data
//         setFilteredData(response.data);  // Set the filtered data initially as the full dataset
//       })
//       .catch((err) => {
//         setError('Error fetching data');
//         console.error(err);
//       })
//       .finally(() => {
//         setLoading(false); // Turn off loading state after request is done
//       });
//   }, []);

//   // Filter data based on selected year and month
//   const handleSearch = () => {
//     if (!year || !month) {
//       setError('Please select both Year and Month');
//       return;
//     }

//     // Apply filter to the stored data based on the selected year and month
//     const filtered = data.filter(item => {
//       const itemYear = item.year; // Assuming each item has a year property
//       const itemMonth = item.month; // Assuming each item has a month property
//       return itemYear === parseInt(year) && itemMonth === months.indexOf(month) + 1;
//     });

//     setFilteredData(filtered); // Set filtered data
//   };

//   return (
//     <div className="payment-status">
//       <h2>Payment Status</h2>

//       {/* Filters */}
//       <div className="filters">
//         <select
//           value={year}
//           onChange={(e) => setYear(e.target.value)}
//           disabled={loading}
//         >
//           <option value="">Select Year</option>
//           {years.map((y) => (
//             <option key={y} value={y}>{y}</option>
//           ))}
//         </select>

//         <select
//           value={month}
//           onChange={(e) => setMonth(e.target.value)}
//           disabled={loading}
//         >
//           <option value="">Select Month</option>
//           {months.map((m) => (
//             <option key={m} value={m}>{m}</option>
//           ))}
//         </select>

//         <button
//           onClick={handleSearch}
//           disabled={loading || !year || !month}
//         >
//           Search
//         </button>
//       </div>

//       {/* Loading */}
//       {loading && <p>Loading...</p>}

//       {/* Error Message */}
//       {error && <p style={{ color: 'red' }}>{error}</p>}

//       {/* Display filtered data */}
//       {!loading && !error && filteredData.length > 0 && (
//         <div className="payment-details">
//           <p><strong>Total Enrollment:</strong> {filteredData[0].totalEnrollment}</p>
//           <p><strong>RFF Paid:</strong> {filteredData[0].rffPaid}</p>
//           <p><strong>RFF Due:</strong> {filteredData[0].rffDue}</p>
//         </div>
//       )}

//       {/* If no data found after filter */}
//       {filteredData.length === 0 && !loading && !error && (
//         <p>No data found for the selected filters.</p>
//       )}
//     </div>
//   );
// };

// export default Payment;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Spinner, Alert } from 'react-bootstrap'; // Import React-Bootstrap components

const Payment = () => {
  // State variables
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Predefined years and months (can be dynamic)
  const years = [2021, 2022, 2023, 2024];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Fetch data when the component mounts using .then() and .catch()
  useEffect(() => {
    setLoading(true);
    setError(null);

    axios
      .get('/api/payment-status') // Make GET request with Axios
      .then((response) => {
        setData(response.data);  // Store the full data
        setFilteredData(response.data);  // Set the filtered data initially as the full dataset
      })
      .catch((err) => {
        setError('Error fetching data');
        console.error(err);
      })
      .finally(() => {
        setLoading(false); // Turn off loading state after request is done
      });
  }, []);

  // Filter data based on selected year and month
  const handleSearch = () => {
    if (!year || !month) {
      setError('Please select both Year and Month');
      return;
    }

    // Apply filter to the stored data based on the selected year and month
    const filtered = data.filter(item => {
      const itemYear = item.year; // Assuming each item has a year property
      const itemMonth = item.month; // Assuming each item has a month property
      return itemYear === parseInt(year) && itemMonth === months.indexOf(month) + 1;
    });

    setFilteredData(filtered); // Set filtered data
  };

  return (
    <div className="payment-status">
      <h2>Payment Status</h2>

      {/* Filters using React-Bootstrap */}
      <Row className="mb-3">
        <Col md={4}>
          <Form.Group controlId="yearSelect">
            <Form.Label>Year</Form.Label>
            <Form.Control
              as="select"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              disabled={loading}
            >
              <option value="">Select Year</option>
              {years.map((y) => (
                <option key={y} value={y}>{y}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={4}>
          <Form.Group controlId="monthSelect">
            <Form.Label>Month</Form.Label>
            <Form.Control
              as="select"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              disabled={loading}
            >
              <option value="">Select Month</option>
              {months.map((m) => (
                <option key={m} value={m}>{m}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md={4} className="d-flex align-items-end">
          <Button
            onClick={handleSearch}
            variant="primary"
            disabled={loading || !year || !month}
          >
            {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Search'}
          </Button>
        </Col>
      </Row>

      {/* Loading */}
      {loading && <Spinner animation="border" variant="primary" />}

      {/* Error Message */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Display filtered data */}
      {!loading && !error && filteredData.length > 0 && (
        <div className="payment-details">
          <p><strong>Total Enrollment:</strong> {filteredData[0].totalEnrollment}</p>
          <p><strong>RFF Paid:</strong> {filteredData[0].rffPaid}</p>
          <p><strong>RFF Due:</strong> {filteredData[0].rffDue}</p>
        </div>
      )}

      {/* If no data found after filter */}
      {filteredData.length === 0 && !loading && !error && (
        <Alert variant="warning">No data found for the selected filters.</Alert>
      )}
    </div>
  );
};

export default Payment;
