import React, { createContext, useState, useContext } from 'react';

// Create context for form data and current step
const FormContext = createContext();

// Provider component to manage form state
export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    personalInfo: {
      fullName: '',
      dob: '',
      gender: '',
      nationality: '',
      maritalStatus: '',
      mobile: '',
      email: '',
      address: '',
      pincode: '',
      passportNumber: '',
      expiryDate: '',
    },
    businessInfo: {
      occupation: '',
      companyName: '',
      businessAddress: '',
      businessAddressLine2: '',
      businessCity: '',
      businessPin: '',
      yearsOfExperience: '',
      industryExperience: '',
      franchiseOwnership: '',
      franchiseDetails: '',
    },
    financialInfo: {
      currentAnnualIncome: '',
      netWorth: '',
      sourceOfFunds: '',
      amountAvailableForInvestment: '',
      bankReferences: '',
      existingLoans: '',
      loanDetails: '',
    },
    franchiseInterest: {
      franchiseInterestReason: '',
      preferredLocation: '',
      startTime: '',
      trainingWillingness: '',
      operateOrHireManager: '',
      longTermGoals: '',
    },
    legalAndCompliance: {
        convictedOfCrime: '',
        convictionDetails: '',
        ongoingLegalProceedings: '',
        legalProceedingsDetails: '',
        adhereToTerms: ''
      },
      declaration: {
        signature: '',
        declarationDate: '',
        place: '',
      },
  });

  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const updateFormData = (section, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        ...data,
      },
    }));
  };

  return (
    <FormContext.Provider value={{ formData, currentStep, nextStep, prevStep, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

// Custom hook to use form context
export const useFormContext = () => {
  return useContext(FormContext);
};
