import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { Row, Col, Container, Button, InputGroup, Form, Table } from "react-bootstrap";

const PaymentRecord = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState("");
  const [courseFilter, setCourseFilter] = useState("");
  const [transactionFilter, setTransactionFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;

  useEffect(() => {
    const fetchPayments = () => {
      axios
        .get("https://api.ocupiiehospitalityacademy.com/api/payement/fetchall")
        .then((response) => {
            console.log(response.data);
          setPaymentData(response.data);
          setFilteredData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching payment data:", error);
        });
    };

    fetchPayments();
  }, []);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = Array.isArray(filteredData)
    ? filteredData.slice(indexOfFirstRecord, indexOfLastRecord)
    : [];

  const totalPages = Math.ceil(filteredData.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  useEffect(() => {
    const applyFilters = () => {
      if (Array.isArray(paymentData)) {
        const newFilteredData = paymentData.filter((item) => {
          const matchesPaymentStatus = paymentStatusFilter
            ? item.Payment_status.toLowerCase().includes(paymentStatusFilter.toLowerCase())
            : true;
          const matchesCourse = courseFilter
            ? item.course_type.Course_Name.toLowerCase().includes(courseFilter.toLowerCase())
            : true;
          const matchesTransaction = transactionFilter
            ? item.Transaction_id.toLowerCase().includes(transactionFilter.toLowerCase())
            : true;

          return matchesPaymentStatus && matchesCourse && matchesTransaction;
        });

        setFilteredData(newFilteredData);
        setCurrentPage(1); // Reset pagination when filters change
      }
    };

    applyFilters();
  }, [paymentStatusFilter, courseFilter, transactionFilter, paymentData]);

  const downloadExcel = () => {
    const dataToDownload = filteredData.length > 0 ? filteredData : paymentData;

    const filteredDataForExcel = dataToDownload.map((item) => ({
      ID: item.id,
      FullName: item.FullName,
      FatherName: item.FatherName,
      Mobile: item.Mobile,
      Email: item.Email,
      EmergencyContact: item.EmergencyContact,
      Address: item.Address,
      State: item.State,
      City: item.City,
      PreferredClassTiming: item.PreferredClassTiming,
      Amount: item.Amount,
      PaymentStatus: item.Payment_status,
      TransactionID: item.Transaction_id,
      Course: item.course_type.Course_Name,
      CourseType: item.course_type.Course_Type,
      PaymentMethod: item.Payment_method,
      PaymentTime: item.Payment_time,
      Duration: item.Duration,
      BankReference: item.Bank_reference,
      OrderID: item.order_id,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payments");
    const filename = filteredData.length > 0 ? "Filtered_Payments" : "All_Payments";
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col sm={3}>
          <InputGroup>
            <InputGroup.Text>Payment Status</InputGroup.Text>
            <Form.Control
              type="text"
              value={paymentStatusFilter}
              onChange={(e) => setPaymentStatusFilter(e.target.value)}
              placeholder="Filter by Payment Status"
            />
          </InputGroup>
        </Col>

        <Col sm={3}>
          <InputGroup>
            <InputGroup.Text>Course</InputGroup.Text>
            <Form.Control
              type="text"
              value={courseFilter}
              onChange={(e) => setCourseFilter(e.target.value)}
              placeholder="Filter by Course"
            />
          </InputGroup>
        </Col>

        <Col sm={3}>
          <InputGroup>
            <InputGroup.Text>Transaction ID</InputGroup.Text>
            <Form.Control
              type="text"
              value={transactionFilter}
              onChange={(e) => setTransactionFilter(e.target.value)}
              placeholder="Filter by Transaction ID"
            />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Button variant="success" className="mt-3" onClick={downloadExcel}>
            <i className="fa fa-download" aria-hidden="true"></i> Download Excel
          </Button>
        </Col>
      </Row>

      {filteredData.length > 0 ? (
        <Container className="text-center">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>ID</th>
                <th>Full Name</th>
                <th>Father Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Emergency Contact</th>
                <th>Address</th>
                <th>State</th>
                <th>City</th>
                <th>Preferred Class Timing</th>
                <th>Amount</th>
                <th>Payment Status</th>
                <th>Transaction ID</th>
                <th>Course</th>
                <th>Course Type</th>
                <th>Payment Method</th>
                <th>Payment Time</th>
                <th>Duration</th>
                <th>Bank Reference</th>
                <th>Order ID</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1 + (currentPage - 1) * recordPerPage}</td>
                  <td>{item.id}</td>
                  <td>{item.FullName}</td>
                  <td>{item.FatherName}</td>
                  <td>{item.Mobile}</td>
                  <td>{item.Email}</td>
                  <td>{item.EmergencyContact}</td>
                  <td>{item.Address}</td>
                  <td>{item.State}</td>
                  <td>{item.City}</td>
                  <td>{item.PreferredClassTiming}</td>
                  <td>{item.Amount}</td>
                  <td>{item.Payment_status}</td>
                  <td>{item.Transaction_id}</td>
                  <td>{item.course_type.Course_Name}</td>
                  <td>{item.course_type.Course_Type}</td>
                  <td>{item.Payment_method}</td>
                  <td>{item.Payment_time}</td>
                  <td>{item.Duration}</td>
                  <td>{item.Bank_reference}</td>
                  <td>{item.order_id}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div>
            <Button
              variant="secondary"
              disabled={currentPage === 1}
              onClick={() => paginate(currentPage - 1)}
            >
              Previous
            </Button>

            {getPageRange().map((number) => (
              <Button
                key={number}
                variant={number === currentPage ? "primary" : "secondary"}
                onClick={() => paginate(number)}
              >
                {number}
              </Button>
            ))}

            <Button
              variant="secondary"
              disabled={currentPage === totalPages}
              onClick={() => paginate(currentPage + 1)}
            >
              Next
            </Button>
          </div>
        </Container>
      ) : (
        <div className="text-center">No records found</div>
      )}
    </Container>
  );
};

export default PaymentRecord;
