import React from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  

  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove token from local storage
    navigate("/"); // Redirect to home page or login
  };

  // If no token, redirect to login page
  if (!token) {
    console.log("No token found, redirecting to /admin/login");
    return <Navigate to="/admin/login" />;
  }

  return (
    <Container fluid className="dashboard-container">
      <Row>
        <Col xs={12} md={3} lg={2} className="sidebar-container">
          <Sidebar />
        </Col>
        <Col xs={12} md={9} lg={10} className="main-content-container justify-content-center">
          <Button onClick={handleLogout} style={{ marginBottom: "10px" }}>
            Logout
          </Button>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
