import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaArrowRightLong } from "react-icons/fa6";
import "./WhyChoose.css"

function WhyChoose() {
  const items = [
    "Industry-Ready Skills",
    "Globally Recognized Certifications",
    "Expert Faculty",
    "Job Placement Assistance",
    "Practical Learning Environment",
    "Personalized Career Support",
    "Global Internship Opportunities",
    "Flexible Learning Options",
    "Comprehensive Course Offerings",
    "A Pathway to Global Careers",
  ];

  return (
    <Container fluid={true} >
      <Row className="justify-content-center">
        <Col
          xm={12}
          md={5}
          lg={4}
          xl={4}      
          className="listcss">
          <h2 className="text-center mb-4 mt-3" style={{ fontFamily: "math-italic"}}>Why Choose<strong style={{color:"#ffad00"}}><br/>Ocupiie Hospitality Academy</strong>?</h2>
          </Col>
          </Row>
          <Row xs={1} sm={2} md={3} lg={3} className="g-4 mb-2" >
            {items.map((item, index) => (
              <Col key={index}>
                <Card className="shadow-sm cardmain">
                  <Card.Body className="shadowBody">
                    <Card.Title>
                      <FaArrowRightLong style={{ color: "#9acd32", marginRight: "10px" }} />
                      {item}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>     
    </Container>
  );
}

export default WhyChoose;
