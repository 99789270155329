import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormContext } from "./FormContext"; // Make sure this path is correct

const PersonalInformation = () => {
  const { formData, updateFormData } = useFormContext(); // Get form data and update function from context

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData("personalInfo", { [name]: value }); // Ensure proper state update for personalInfo
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateFormData("personalInfo", { [name]: value }); // Update radio button choices
  };

  return (
    <div>
      <h3>Section 1: Personal Information</h3>
      <Form>
        {/* Full Name */}
        <Form.Group controlId="fullName">
          <Form.Label>1. Full Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Full Name"
            name="fullName"
            value={formData.personalInfo?.fullName || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Date Of Birth */}
        <Form.Group controlId="dob">
          <Form.Label>2. Date Of Birth:</Form.Label>
          <Form.Control
            type="date"
            name="dob"
            value={formData.personalInfo?.dob || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Gender */}
        <Form.Group controlId="gender">
          <Form.Label>3. Gender:</Form.Label>
          <Row>
            <Col sm={4}>
              <Form.Check
                type="radio"
                label="Male"
                name="gender"
                value="Male"
                checked={formData.personalInfo?.gender === "Male"}
                onChange={handleRadioChange}
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                type="radio"
                label="Female"
                name="gender"
                value="Female"
                checked={formData.personalInfo?.gender === "Female"}
                onChange={handleRadioChange}
              />
            </Col>
            <Col sm={4}>
              <Form.Check
                type="radio"
                label="Other"
                name="gender"
                value="Other"
                checked={formData.personalInfo?.gender === "Other"}
                onChange={handleRadioChange}
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Nationality */}
        <Form.Group controlId="nationality">
          <Form.Label>4. Nationality:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Nationality"
            name="nationality"
            value={formData.personalInfo?.nationality || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Marital Status */}
        <Form.Group controlId="maritalStatus">
          <Form.Label>5. Marital Status:</Form.Label>
          <Row>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="Single"
                name="maritalStatus"
                value="Single"
                checked={formData.personalInfo?.maritalStatus === "Single"}
                onChange={handleRadioChange}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="Married"
                name="maritalStatus"
                value="Married"
                checked={formData.personalInfo?.maritalStatus === "Married"}
                onChange={handleRadioChange}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="Divorced"
                name="maritalStatus"
                value="Divorced"
                checked={formData.personalInfo?.maritalStatus === "Divorced"}
                onChange={handleRadioChange}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="Widow"
                name="maritalStatus"
                value="Widow"
                checked={formData.personalInfo?.maritalStatus === "Widow"}
                onChange={handleRadioChange}
              />
            </Col>
            <Col sm={3}>
              <Form.Check
                type="radio"
                label="Other"
                name="maritalStatus"
                value="Other"
                checked={formData.personalInfo?.maritalStatus === "Other"}
                onChange={handleRadioChange}
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Contact Information */}
        <Form.Group controlId="contactInfo">
          <Form.Label>6. Contact Information:</Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Control
                type="tel"
                placeholder="Mobile Number"
                name="mobile"
                value={formData.personalInfo?.mobile || ""}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col sm={6}>
              <Form.Control
                type="email"
                placeholder="Email Address"
                name="email"
                value={formData.personalInfo?.email || ""}
                onChange={handleInputChange}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
                value={formData.personalInfo?.address || ""}
                onChange={handleInputChange}
                required
                className="mt-2"
              />
            </Col>
            <Col>
              <Form.Control
                type="number"
                placeholder="Pin Code"
                name="pincode"
                value={formData.personalInfo?.pincode || ""}
                onChange={handleInputChange}
                required
                className="mt-2"
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Identification Details */}
        <Form.Group controlId="identificationDetails">
          <Form.Label>7. Identification Details:</Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Control
                type="text"
                placeholder="Aadhar Number"
                name="aadharNumber"
                value={formData.personalInfo?.aadharNumber || ""}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col sm={6}>
              <Form.Control
                type="text"
                placeholder="Passport Number"
                name="passportNumber"
                value={formData.personalInfo?.passportNumber || ""}
                onChange={handleInputChange}
                required
              />
            </Col>
          </Row>
          <Form.Group controlId="expiryDate" className="mt-2">
            <Form.Label>Expiry Date:</Form.Label>
            <Form.Control
              type="date"
              name="expiryDate"
              value={formData.personalInfo?.expiryDate || ""}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
        </Form.Group>
      </Form>
    </div>
  );
};

export default PersonalInformation;
