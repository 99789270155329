// import React, { useState, useEffect, useMemo } from "react";
// import { Card, Col, Row } from "react-bootstrap";
// import "./WhyForeignLanguage.css";

// const WhyForeignLanguage = () => {
//   // UseMemo to memoize the headings array with background images for each section
//   const headings = useMemo(
//     () => [
//       {
//         title: "Career Opportunities",
//         points: [
//           "Higher Earnings Potential",
//           "Global Job Market",
//           "Competitive Edge",
//         ],
//         image: "/images/Career-Opportunities.png",
//       },
//       {
//         title: "Adapt to the Global Economy",
//         points: [
//           "International Business",
//           "Access to Information",
//           "Study Abroad",
//         ],
//         image: "/images/Adapt-to-the-Global-Economy.png", // Add the corresponding image path
//       },
//       {
//         title: "Improve Communication Skills",
//         points: [
//           "Bridge Cultural Gaps",
//           "Effective Networking",
//           "Adaptability",
//         ],
//         image: "/images/Improve-Communication-Skills.png", // Add the corresponding image path
//       },
//       {
//         title: "Personal Growth and Fulfillment",
//         points: [
//           "Sense of Achievement",
//           "Social Opportunities",
//           "Life-long Learning",
//         ],
//         image: "/images/Personal-Growth-and-Fulfillment.png", // Add the corresponding image path
//       },
//       {
//         title: "Cultural Awareness",
//         points: [
//           "Understand New Perspectives",
//           "Appreciation for Diversity",
//           "Travel Benefits",
//         ],
//         image: "/images/Cultural-Awareness.png", // Add the corresponding image path
//       },
//     ],
//     []
//   );

//   // State to track the current heading index
//   const [currentIndex, setCurrentIndex] = useState(0);

//   // Slide every 5 seconds for heading change
//   useEffect(() => {
//     const headingIntervalId = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % headings.length);
//     }, 5000); // Change content every 5 seconds

//     return () => clearInterval(headingIntervalId); // Clean up the interval on unmount
//   }, [headings.length]);

//   return (
//     <div className="mt-3 m-0 p-0">
//       <Row className="p-0 m-0 justify-content-center">
//         <Col
//           xs={12}
//           sm={8}
//           md={7}
//           lg={8}
//           xl={8}
//           className="d-flex flex-column justify-content-center align-items-center"
//         >
//           <p className="badges m-0">
//             Why is Learning a Foreign Language Essential?
//           </p>
//         </Col>
//         <Col xs={12} sm={8} md={5} lg={4} xl={4} className="p-0 cardcolomncss">
//           <Card
//             className="text-center m-0 p-0 cardcss"
//             style={{
//               backgroundImage: `url(${headings[currentIndex].image})`, // Dynamically change the background image
//               backgroundSize: "cover",
//               backgroundPosition: "center",
//               backgroundRepeat: "no-repeat",
//               width: "auto",
//             }}
//           >
//             <Card.Header className="cardcsstitle text-center">
//               {headings[currentIndex].title}
//             </Card.Header>
//             <Card.Body className="d-flex flex-column justify-content-center align-items-center p-0">
//               <div
//                 className="points-list"
//                 style={{
//                   height: "140px", // Fixed height for the points section
//                   overflowY: "auto", // Enable scrolling if points overflow
//                   padding: "0px", // Adjust padding if necessary
//                   display: "block", // Ensure points are displayed in a block (vertically stacked)
//                   justifyContent: "center", // Ensure content is centered
//                   textAlign: "center", // Center align the text horizontally
//                 }}
//               >
//                 {headings[currentIndex].points.map((point, index) => (
//                   <Card.Text
//                     key={index}
//                     className="text-center pb-1 points-list-card"
//                   >
//                     {point}
//                   </Card.Text>
//                 ))}
//               </div>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default WhyForeignLanguage;

import React, { useState, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import "./WhyForeignLanguage.css";

const WhyForeignLanguage = () => {
  // UseMemo to memoize the headings array with background images for each section
  const headings = useMemo(
    () => [
      {
        image: "/images/Career-Opportunities.png",
      },
      {
        image: "/images/Adapt-to-the-Global-Economy.png",
      },
      {
        image: "/images/Improve-Communication-Skills.png",
      },
      {
        image: "/images/Personal-Growth-and-Fulfillment.png",
      },
      {
        image: "/images/Cultural-Awareness.png",
      },
    ],
    []
  );

  // State to track the current image index
  const [currentIndex, setCurrentIndex] = useState(0);

  // Slide every 5 seconds for image change
  useEffect(() => {
    const imageIntervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % headings.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(imageIntervalId); // Clean up the interval on unmount
  }, [headings.length]);

  return (
    <div className="mt-3 m-0 p-0">
      <Row className="p-0 m-0 justify-content-center">
        <Col
          xs={12}
          sm={8}
          md={7}
          lg={8}
          xl={8}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <p className="badges m-0">
            Why is Learning a Foreign Language Essential?
          </p>
        </Col>
        <Col xs={12} sm={8} md={5} lg={4} xl={4} className="p-0 cardcolomncss">
          <div
            className="image-slide"
            style={{
              backgroundImage: `url(${headings[currentIndex].image})`, // Dynamically change the background image
              backgroundSize: "contain", // Ensure the image fits within the container without cutting off
              backgroundPosition: "center", // Center the image in the container
              backgroundRepeat: "no-repeat", // Avoid tiling
              height: "250px", // Set a height for the image container
              width: "100%", // Make the container span full width
            }}
          ></div>
        </Col>
      </Row>
    </div>
  );
};

export default WhyForeignLanguage;
