import React, { useState} from "react";
import { MdMessage } from "react-icons/md"; // Message icon from Material Icons
import "bootstrap/dist/css/bootstrap.min.css";
import "./LanguageOnline.css";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import EnquiryFormLanguage from "../EnquiryForm/EnquiryFormLanguage";
import MultiStepForm from "../MultiStepForm";
import FeedbackForm from "./FeedbackForm";
import WhyForeignLanguage from "../WhyForeignLanguage/WhyForeignLanguage";
import VideoGrid from "../VideoGrid/VideoGrid";
import CareerOpportunities from "./CareerOpportunities";

const LanguageOnline = () => {
  const CoursesLinks = [
    {
      id: 1,
      name: "English",
      image: "images/englishonliness.png",
      link: "/courses/english",
    },
    {
      id: 2,
      name: "French",
      image: "images/frenchonline.png",
      link: "/courses/french",
    },
    {
      id: 3,
      name: "German",
      image: "images/germanonline.png",
      link: "/courses/german",
    },
    {
      id: 4,
      name: "Arabic",
      image: "images/arabiconline.png",
      link: "/courses/arabic",
    },
    {
      id: 5,
      name: "Spanish",
      image: "images/spanishonline.png",
      link: "/courses/spanish",
    },
  ];

  const courses = [
    {
      id: 1,
      name: "English",
      singleFee: 18720,
      groupFee: 9000,
      duration: "12 weeks, 3 days per week",
      imageUrl: "images/engsss.jpg",
    },
    {
      id: 2,
      name: "French",
      singleFee: 21060,
      groupFee: 10530,
      duration: "12 weeks, 3 days per week",
      imageUrl: "images/franceonlines.jpg",
    },
    {
      id: 3,
      name: "German",
      singleFee: 14040,
      groupFee: 7020,
      duration: "08 weeks, 3 days per week",
      imageUrl: "images/germanonlines.jpg",
    },
    {
      id: 4,
      name: "Spanish",
      singleFee: 14040,
      groupFee: 7020,
      duration: "08 weeks, 3 days per week",
      imageUrl: "images/spanishonlines.jpg",
    },
    {
      id: 5,
      name: "Arabic",
      singleFee: 18720,
      groupFee: 9000,
      duration: "12 weeks, 3 days per week",
      imageUrl: "images/arabiconlines.jpg",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null); // 'enquiry' or 'feedback'
  const [showForm, setShowForm] = useState(false); // State for the multi-step form visibility
  const [selectedCourse, setSelectedCourseState] = useState(null); // State for the selected course

  const handleClose = () => setShowModal(false);

  const handleShowForm = () => {
    setShowModal(false); // Close the modal if it's open
    setShowForm(true); // Show the multi-step form
  };

  const handleCloseForm = () => {
    setShowForm(false); // Close the form
  };

  const handleSelectCourse = (course) => {
    setSelectedCourseState(course); // Set the selected course
    handleShowForm(); // Show the multi-step form
  };

  const handleEnquiryClick = () => {
    setModalType("enquiry"); // Set modal type to 'enquiry'
    setShowModal(true); // Show the modal
  };

  const handleFeedbackClick = () => {
    setModalType("feedback"); // Set modal type to 'feedback'
    setShowModal(true); // Show the modal
  };

  return (
    <Container fluid={true} className="p-0 m-0">
      {/* learn language with logo on top */}
      <Row className="position-relative p-0 m-0 ">
        <Col className="p-0">
          <div className="image-container" style={{ position: "relative" }}>
            {/* Company logo overlay on image */}
            <Link to="/">
              <Image
                src={`${process.env.PUBLIC_URL}/logo1.png`}
                alt="OCUPIIE Logo"
                className="logoLanguage"
                style={{
                  position: "absolute",
                  top: "0px", // Adjust the vertical position
                  right: "20px", // Adjust the horizontal position
                  maxWidth: "100px", // Adjust the size of the logo
                  zIndex: "10", // Ensures logo is above the image
                }}
              />
            </Link>

            {/* Background Image */}
            <Image
              className="d-block w-100 img-fluid frenchaisies"
              src="images/learn_lang_online.png"
              alt="Learn Language"
            />

            {/* Button on the right side */}

            <Button
              className="enquirenow"
              onClick={handleEnquiryClick}
              style={{
                position: "fixed",
                top: "70%",
                right: "50px",
                zIndex: "10",
                color: "white",
                backgroundColor: "#9acd32", // Match your logo color
                border: "1px",
                fontWeight: "bold",
                transform: "rotate(90deg)", // Rotate the button
                transformOrigin: "bottom right", // Set the origin of the rotation to the bottom right corner
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Enquiry Now
            </Button>

           
            <Button
              className="enquirenowh"
              onClick={handleFeedbackClick}
              style={{
                position: "fixed",
                top: "80%",
                left: "5px",
                zIndex: "10",
                color: "white",
                backgroundColor: "transparent",
                border: "1px solid",
                fontWeight: "bold",
                transform: "rotate(0deg)",
                transformOrigin: "bottom right",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column", // This ensures the icon and text are stacked vertically
                padding: "10px", // Optional, to add some padding around the button
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <MdMessage size={40} color="black" />
                <span
                  style={{
                    marginTop: "5px",
                    color: "#080809",
                    fontSize: "12px",
                  }}
                >
                  Feedback Form
                </span>{" "}
                {/* Adjust text styling here */}
              </div>
            </Button>
          </div>
        </Col>
      </Row>

      {/* language button */}

      <Row className="p-0 m-0 justify-content-center mt-3">
        {CoursesLinks.map((CoursesLinks) => (
          <Col
            key={CoursesLinks.id}
            xs={4}
            sm={3}
            md={2}
            lg={2}
            xl={2}
            xxl={2}
            className="fit-content-card  justify-content-center"
          >
            <Card className="text-center border-0 d-flex flex-column align-items-center justify-content-center">
              <Image
                variant="top"
                className="card-img-customm" // Apply custom CSS class
                src={CoursesLinks.image}
                alt={CoursesLinks.name} // Add alt text for accessibility
              />
              <Card.Body className="p-0 m-0">
                <Card.Title>
                  <Link to={CoursesLinks.link} className="language-linkss">
                    {CoursesLinks.name}
                  </Link>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* join our */}
      <Row className="position-relative p-0 m-0 mt-3">
        <Col
          xs={12}
          sm={8}
          md={6}
          lg={6}
          className="fit-content-card d-flex justify-content-center"
        >
          <Image
            className="d-block img-fluid "
            src="images/onlinelearnings.jpg"
            alt="First slide"
          />
        </Col>

        <Col
          xs={12}
          sm={8}
          md={6}
          lg={6}
          className="fit-content-card mt-5 pt-5"
        >
          <div className="ourjoin">
            <p>
              <strong className="join-text">Join</strong>
            </p>
            <p>
              <strong className="our-text"> our </strong>
              <strong className="training-text">
                Online Language Training
              </strong>
            </p>
            <p>
              <strong className="today-text">
                today and take a step toward global opportunities with enhanced
                language skills.
              </strong>
            </p>
          </div>
        </Col>
      </Row>

      {/* course details */}

      <Row className=" justify-content-center mt-5 p-0 m-0">
        <Col sm={12} className="text-center">
          <h2 className="button-like">Get The Courses As Per Your Needs </h2>
        </Col>

        {courses.map((course) => (
          <Col
            className="colomnclassname mb-2"
            key={course.id}
            xs={9}
            sm={6}
            md={4}
            lg={4}
            xxl={2}
          >
            <Card className="cardcontainer">
              <Card.Img
                variant="top"
                src={course.imageUrl}
                alt="Card Image"
                className="img-fluid"
                style={{
                  height: "", // Optional: sets a consistent height
                  objectFit: "cover", // Crops to fit while keeping the center of the image visible
                }}
              />
              <Card.Body className="cardbodycolor p-0 m-0">
                <Card.Title className="namelanguage p-0 m-0 ">
                  {course.name}
                </Card.Title>
                <Card.Text
                  className="mx-1 p-0 m-0"
                  style={{ fontSize: "15px" }}
                >
                  <span>Duration:</span><strong>{course.duration}</strong> <br />
                  <strong >Course Fees</strong> <br />
                  <span>Single:</span> <strong>{course.singleFee} INR</strong> <br />
                  <span> Group (5 persons):</span> <strong>{course.groupFee} INR</strong>
                </Card.Text>
                <Button
                  className="buttoncourse px-1 m-0"
                  variant="primary"
                  onClick={() => handleSelectCourse(course)}
                >
                  I want to Learn
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div>
      <WhyForeignLanguage/>
      </div>
      <div>
        <CareerOpportunities/>
      </div>

      <Row className="position-relative justify-content-center p-0 m-0 mt-4">
        <Col
          xs={12}
          sm={8}
          md={6}
          lg={5}
          className="fit-content-card p-0 m-0 px-3 pt-4  colomnimagesss"
        >
          <Image
            className=" img-fluid fitContents"
            src="images/weoffers.jpg"
            alt="First slide"
          />
        </Col>
        <Col xs={12} sm={8} md={6} lg={7} className="px-3 classcolomnborder">
          <h3>
            <span style={{ color: "#ffad00" }}>We Offer</span> comprehensive
            online language training designed to help you master new languages
            from the comfort of your home. Our courses are tailored for
            hospitality professionals and anyone looking to enhance their global
            communication skills.
          </h3>
          {/* Your content goes here */}
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col className="m-0 p-0">
          <VideoGrid/>
        </Col>
      </Row>

      {/* Modal for enquiry and feedback */}
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              backgroundColor: "#fffcc7",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            {modalType === "enquiry" && <EnquiryFormLanguage />}
            {modalType === "feedback" && <FeedbackForm />}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal for the multi-step form  */}
      <Modal
        show={showForm}
        onHide={handleCloseForm}
        fullscreen={true} // This makes the modal full-screen
        backdrop="static"
        dialogClassName="custom-modal-styles"
        keyboard={false}
        centered="true"
        style={{
          overflowY: "auto",
        }}
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", backgroundColor: "#f8f9fa" }}
        >
          <Modal.Title centered="true"></Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            width: "70%",
            margin: "auto",
            overflowY: "auto",
            maxHeight: "calc(100vh - 100px)",
            padding: "5px",
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: "#fffcc7",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <MultiStepForm
              onClose={handleCloseForm}
              selectedCourse={selectedCourse}
            />
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default LanguageOnline;
