import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { useFormContext } from "./FormContext"; // Ensure this path is correct

const FinancialInformation = () => {
  const { formData, updateFormData } = useFormContext(); // Get form data and update function from context

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData("financialInfo", { [name]: value });
  };

  const handleLoanChange = (e) => {
    const { value } = e.target;
    updateFormData("financialInfo", { loanDetails: value });
  };

  return (
    <div>
      <h3>Section 3: Financial Information</h3>
      <Form>
        {/* Current Annual Income */}
        <Form.Group controlId="currentAnnualIncome">
          <Form.Label>1. Current Annual Income:</Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Enter Annual Income"
              name="currentAnnualIncome"
              value={formData.financialInfo.currentAnnualIncome || ""}
              onChange={handleInputChange}
              required
              min="0" // Ensure only positive values are allowed
            />
          </InputGroup>
        </Form.Group>

        {/* Net Worth */}
        <Form.Group controlId="netWorth">
          <Form.Label>2. Net Worth:</Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Enter Net Worth"
              name="netWorth"
              value={formData.financialInfo.netWorth || ""}
              onChange={handleInputChange}
              required
              min="0" // Ensure only positive values are allowed
            />
          </InputGroup>
        </Form.Group>

        {/* Source of Funds */}
        <Form.Group controlId="sourceOfFunds">
          <Form.Label>3. Source of Funds for Franchise Investment:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Source of Funds"
            name="sourceOfFunds"
            value={formData.financialInfo.sourceOfFunds || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Amount Available for Investment */}
        <Form.Group controlId="amountAvailableForInvestment">
          <Form.Label>4. Amount Available for Investment:</Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Enter Amount Available"
              name="amountAvailableForInvestment"
              value={formData.financialInfo.amountAvailableForInvestment || ""}
              onChange={handleInputChange}
              required
              min="0" // Ensure only positive values are allowed
            />
          </InputGroup>
        </Form.Group>

        {/* Bank References */}
        <Form.Group controlId="bankReferences">
          <Form.Label>5. Bank References:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Bank References"
            name="bankReferences"
            value={formData.financialInfo.bankReferences || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Radio Buttons for Existing Loans */}
        <Form.Group controlId="existingLoans">
          <Form.Label>6. Do you have any existing loans?</Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="Yes"
                name="existingLoans"
                value="yes"
                checked={formData.financialInfo.existingLoans === "yes"}
                onChange={handleInputChange}
              />
            </Col>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="No"
                name="existingLoans"
                value="no"
                checked={formData.financialInfo.existingLoans === "no"}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Conditional rendering for loan details */}
        {formData.financialInfo.existingLoans === "yes" && (
          <Form.Group controlId="loanDetails">
            <Form.Label>If yes, please provide details:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter loan details"
              name="loanDetails"
              value={formData.financialInfo.loanDetails || ""}
              onChange={handleLoanChange}
            />
          </Form.Group>
        )}
      </Form>
    </div>
  );
};

export default FinancialInformation;
