import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2>Admin Dashboard</h2>
      <ListGroup>
        <ListGroup.Item action variant="light" as={Link} to="/admin/student-inquiry">
          Hospitality Student Enquiry
        </ListGroup.Item>
        <ListGroupItem action variant="light" as={Link} to="/admin/business-inquiry">
          Business Enquiry
        </ListGroupItem>
        <ListGroupItem action variant="light" as={Link} to="/admin/online-language-inquiry">
          Online Language Enquiry
        </ListGroupItem>
        <ListGroupItem action variant="light" as={Link} to="/admin/feedback-inquiry">
          FeedBack Enquiry
        </ListGroupItem>
        <ListGroupItem action variant="light" as={Link} to="/admin/payment-details">
          Payment Details
        </ListGroupItem>

        <ListGroupItem action variant="light" as={Link} to="/admin/paid-payment-details">
         Enroll Student
        </ListGroupItem>
       
       <ListGroupItem action variant="light" as={Link} to= "/admin/franchise-form">
        Franchise
       </ListGroupItem>
        <ListGroupItem action variant="light" as={Link} to="/admin/video-list">
          Video List
        </ListGroupItem>

      </ListGroup>
      
    </div>
  );
};

export default Sidebar;
