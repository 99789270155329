import React from 'react'
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { Container,Row,Col,Button} from 'react-bootstrap'
import StudentSidebar from './StudentSidebar';

const  StudentDashboard = ()=> {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
  
    const handleLogout = () => {
      localStorage.removeItem("token"); // Remove token from local storage
      navigate("/"); // Redirect to home page
    };
  
    if (!token) {
      return <Navigate to="/student/logins" />;
    }
  return (
    <Container fluid={true} className="dashboard-containers">
        <Row>
            <Col xs={12} md={3} lg={2} className="sidebar-containers">
             <StudentSidebar/>
            </Col>
            <Col xs={12} md={9} lg={10} className="main-content-containers justify-content-center">
            <Button onClick={handleLogout} style={{ marginBottom: "10px" }}>
                Logout
              </Button>
              <Outlet />
            </Col>
        </Row>
    </Container>
  )
}

export default StudentDashboard