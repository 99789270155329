import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Table, Button, Spinner } from "react-bootstrap";
import ViewEbook from './ViewEbook';



const Ebook = () => {
  const [ebooks, setEbooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEbook, setSelectedEbook] = useState(null); // For the selected ebook
  const [showViewModal, setShowViewModal] = useState(false); // For viewing the ebook details
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10; // Number of records per page

  // Fetch ebooks when the component mounts
  useEffect(() => {
    const fetchEbooks = () => {
      axios.get("https://api.ocupiiehospitalityacademy.com/api/ebooks/ftechall")
        .then((response) => {
          if (response.status === 200) {
            setEbooks(response.data.ebooks);
          } else {
            throw new Error(`API Error: ${response.status}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching ebooks:", error);
          setError("An error occurred: " + (error.response ? error.response.data : error.message));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchEbooks();
  }, []);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentEbooks = ebooks.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(ebooks.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading ebooks...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div style={{ textAlign: "center", color: "red" }}>{error}</div>;
  }

  // Handle viewing ebook details
  const handleView = (ebook) => {
    setSelectedEbook(ebook); // Set the selected ebook to view details
    setShowViewModal(true); // Show the view ebook modal
  };

  return (
    <Container>
      <Container className="text-center">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Thumbnail</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentEbooks.map((ebook) => (
              <tr key={ebook.id}>
                <td>{ebook.id}</td>
                <td>{ebook.title}</td>
                <td>
                  <img
                    src={`https://api.ocupiiehospitalityacademy.com/${ebook.thumbnail}`}
                    alt={ebook.title}
                    style={{ width: "100px", height: "auto" }}
                  />
                </td>
                <td>
                  <Button variant="info" onClick={() => handleView(ebook)} className="ml-2">
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Pagination controls */}
        <div className="pagination-controls">
          <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </Button>
          {getPageRange().map((page) => (
            <Button
              key={page}
              onClick={() => paginate(page)}
              active={page === currentPage}
            >
              {page}
            </Button>
          ))}
          <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </Button>
        </div>
      </Container>

      {/* View Ebook Modal */}
      {showViewModal && selectedEbook && (
        <ViewEbook
          showModal={showViewModal}
          setShowModal={setShowViewModal}
          ebook={selectedEbook}
        />
      )}
    </Container>
  );
};

export default Ebook;
