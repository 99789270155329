import React, { useEffect, useState } from 'react';
import axios from 'axios';

const StudentProfile = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Example API URL
    // Making an API call using axios with .then() and .catch()
    const token = localStorage.getItem("token");
    axios
    .get("https://api.ocupiiehospitalityacademy.com/api/language-enquiry/fetchall?", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        // On success, set the fetched data into the state
        setData(response.data);
        setLoading(false); // Once the data is loaded, stop the loading state
      })
      .catch(error => {
        // On error, set the error message into the state
        setError(error.message);
        setLoading(false); // Stop loading state
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={styles.container}>
      <h2>User Details</h2>
      <div style={styles.detailsContainer}>
        <div style={styles.detailItem}>
          <strong>Name:</strong> {data.FullName}
        </div>
        <div style={styles.detailItem}>
          <strong>Mobile:</strong> {data.Mobile}
        </div>
        <div style={styles.detailItem}>
          <strong>Email:</strong> {data.Email}
        </div>
        <div style={styles.detailItem}>
          <strong>Course Name:</strong> {data.Course_Name}
        </div>
      </div>
    </div>
  );
};

// Sample styles
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  detailsContainer: {
    marginTop: '10px',
  },
  detailItem: {
    marginBottom: '8px',
    fontSize: '16px',
  },
};

export default StudentProfile;
