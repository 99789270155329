import React from "react";
import "./Business.css";
import { Link } from 'react-router-dom';

const Student = () => {


  return (
    <div className="corporate-links">
      {/* Center the content in the column */}
      <h5>For Students</h5> {/* Main label "For Students" */}
      <a
        href="https://drive.google.com/file/d/10drGapHX906SyocNEmTtIPeTtR5chAUs/view?usp=sharing"
        className="corporate-link"
      >
        <strong>Download Your Prospectus</strong>
      </a>
      <button  className="corporate-link"><Link to="/check-enrollment" style={{ textDecoration: 'none', color: '#080809'}}>Check Your Enrollment</Link></button>
      <button  className="corporate-link"> <Link to="/varify-enrollment" style={{ textDecoration: 'none',color:'#080809'}}>Verify Your Certificate</Link></button><br/>
      
            <strong className="p-0 m-0">For Placement Support:</strong>{" "}<br/>
            <a href="mailto:placements@ocupiie.com" className="contact-links">
            placements@ocupiie.com
            </a>
          
      
    </div>
  );
};

export default Student;
