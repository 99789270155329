import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormContext } from "./FormContext"; // Ensure this path is correct

const FranchiseInterest = () => {
  const { formData, updateFormData } = useFormContext(); // Get form data and update function from context

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData("franchiseInterest", { [name]: value }); // Update the form data in the correct section
  };

  return (
    <div>
      <h3>Section 4: Franchise Interest</h3>
      <Form>
        {/* Question 1 */}
        <Form.Group controlId="franchiseInterestReason">
          <Form.Label>1. Why are you interested in our franchise?</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter your reason for interest in the franchise"
            name="franchiseInterestReason"
            value={formData.franchiseInterest.franchiseInterestReason || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Question 2 */}
        <Form.Group controlId="preferredLocation">
          <Form.Label>2. Preferred Franchise Location (City/Region):</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter preferred location"
            name="preferredLocation"
            value={formData.franchiseInterest.preferredLocation || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Question 3 */}
        <Form.Group controlId="startTime">
          <Form.Label>3. How soon can you start?</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter how soon you can start"
            name="startTime"
            value={formData.franchiseInterest.startTime || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Question 4: Radio Buttons */}
        <Form.Group controlId="trainingWillingness">
          <Form.Label>4. Are you willing to undergo training?</Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="Yes"
                name="trainingWillingness"
                value="Yes"
                checked={formData.franchiseInterest.trainingWillingness === "Yes"}
                onChange={handleInputChange}
              />
            </Col>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="No"
                name="trainingWillingness"
                value="No"
                checked={formData.franchiseInterest.trainingWillingness === "No"}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Question 5 */}
        <Form.Group controlId="operateOrHireManager">
          <Form.Label>5. Do you plan to operate the franchise yourself or hire a manager?</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your plan for operating the franchise"
            name="operateOrHireManager"
            value={formData.franchiseInterest.operateOrHireManager || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Question 6 */}
        <Form.Group controlId="longTermGoals">
          <Form.Label>6. What are your long-term goals with this franchise?</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your long-term goals"
            name="longTermGoals"
            value={formData.franchiseInterest.longTermGoals || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default FranchiseInterest;
