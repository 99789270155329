import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StudentEbook = () => {
  const [ebooks, setEbooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEbook, setSelectedEbook] = useState(null);

  // Fetch the eBooks from the backend
  useEffect(() => {
    const apiUrl = "https://api.ocupiiehospitalityacademy.com/api/language-enquiry/fetchall";  // Replace with your API URL

    axios.get(apiUrl)
      .then(response => {
        // Assuming the response contains an array of eBooks in the `ebooks` field
        setEbooks(response.data.ebooks); 
        setLoading(false); // Stop loading once data is fetched
      })
      .catch(error => {
        setError(error.message); // Handle error
        setLoading(false);
      });
  }, []);

  const handleViewClick = (ebookUrl) => {
    // Open the eBook in a new tab when clicked
    window.open(ebookUrl, '_blank');
  };

  const handleSelectEbook = (ebook) => {
    // Set the selected eBook and display it in the iframe
    setSelectedEbook(ebook);
  };

  if (loading) return <div>Loading eBooks...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={styles.container}>
      <h2>eBook Viewer (Read-only Mode)</h2>

      {/* Display eBooks List */}
      <div style={styles.ebooksList}>
        <h3>Available eBooks</h3>
        {ebooks.length === 0 ? (
          <p>No eBooks available</p>
        ) : (
          <ul>
            {ebooks.map((ebook, index) => (
              <li key={index} style={styles.ebookItem}>
                <button onClick={() => handleSelectEbook(ebook)} style={styles.selectButton}>
                  {ebook.title}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Display selected eBook in Read-only mode */}
      {selectedEbook && (
        <div style={styles.readerContainer}>
          <h3>Reading: {selectedEbook.title}</h3>
          <iframe
            src={selectedEbook.ebookUrl}
            title={selectedEbook.title}
            style={styles.iframe}
            frameBorder="0"
            allowFullScreen
            loading="lazy"
          />
          <div style={styles.buttonContainer}>
            <button style={styles.viewButton} onClick={() => handleViewClick(selectedEbook.ebookUrl)}>
              Open in New Tab (Read-only)
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Sample styles
const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  ebooksList: {
    marginTop: '20px',
  },
  ebookItem: {
    marginBottom: '10px',
  },
  selectButton: {
    padding: '10px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  readerContainer: {
    marginTop: '30px',
  },
  iframe: {
    width: '100%',
    height: '600px', // Adjust height as needed
  },
  buttonContainer: {
    marginTop: '20px',
  },
  viewButton: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default StudentEbook;
