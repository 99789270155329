import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormContext } from "./FormContext"; // Ensure this path is correct

const BusinessInformation = () => {
  const { formData, updateFormData } = useFormContext(); // Get form data and update function from context

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateFormData("businessInfo", { [name]: value }); // Ensure to pass the correct key for "businessInfo"
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    updateFormData("businessInfo", { [name]: value }); // Update radio button choices
  };

  return (
    <div>
      <h3>Section 2: Business Information</h3>
      <Form>
        {/* Current Occupation */}
        <Form.Group controlId="occupation">
          <Form.Label>1. Current Occupation:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Current Occupation"
            name="occupation"
            value={formData.businessInfo?.occupation || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Company Name */}
        <Form.Group controlId="companyName">
          <Form.Label>2. Company Name (if applicable):</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Company Name"
            name="companyName"
            value={formData.businessInfo?.companyName || ""}
            onChange={handleInputChange}
          />
        </Form.Group>

        {/* Business Address */}
        <Form.Group controlId="businessAddress">
          <Form.Label>3. Business Address:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Business Address"
            name="businessAddress"
            value={formData.businessInfo?.businessAddress || ""}
            onChange={handleInputChange}
            required
          />
          <Form.Control
            type="text"
            placeholder="Enter Business Address Line 2"
            name="businessAddressLine2"
            value={formData.businessInfo?.businessAddressLine2 || ""}
            onChange={handleInputChange}
          />
          <Row>
            <Col sm={8}>
              <Form.Control
                type="text"
                placeholder="City"
                name="businessCity"
                value={formData.businessInfo?.businessCity || ""}
                onChange={handleInputChange}
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="number"
                placeholder="Pin Code"
                name="businessPin"
                value={formData.businessInfo?.businessPin || ""}
                onChange={handleInputChange}
                required
                min="100000" // Ensuring pin code is a valid number
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Years of Business Experience */}
        <Form.Group controlId="yearsOfExperience">
          <Form.Label>4. Years of Business Experience:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Years of Business Experience"
            name="yearsOfExperience"
            value={formData.businessInfo?.yearsOfExperience || ""}
            onChange={handleInputChange}
            required
            min="0" // Ensuring it's a positive number
          />
        </Form.Group>

        {/* Industry Experience */}
        <Form.Group controlId="industryExperience">
          <Form.Label>5. Industry Experience (specify industry):</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Industry Experience"
            name="industryExperience"
            value={formData.businessInfo?.industryExperience || ""}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Franchise Ownership */}
        <Form.Group controlId="franchiseOwnership">
          <Form.Label>6. Have you ever owned a franchise?</Form.Label>
          <Row>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="Yes"
                name="franchiseOwnership"
                value="Yes"
                checked={formData.businessInfo?.franchiseOwnership === "Yes"}
                onChange={handleRadioChange}
              />
            </Col>
            <Col sm={6}>
              <Form.Check
                type="radio"
                label="No"
                name="franchiseOwnership"
                value="No"
                checked={formData.businessInfo?.franchiseOwnership === "No"}
                onChange={handleRadioChange}
              />
            </Col>
          </Row>

          {/* If Yes, show the details field */}
          {formData.businessInfo?.franchiseOwnership === "Yes" && (
            <Form.Group controlId="franchiseDetails" className="mt-3">
              <Form.Label>If yes, please provide details:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Franchise Ownership Details"
                name="franchiseDetails"
                value={formData.businessInfo?.franchiseDetails || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          )}
        </Form.Group>
      </Form>
    </div>
  );
};

export default BusinessInformation;
